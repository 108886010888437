import React, { useEffect } from "react";
import "./App.css";
import Hero from "./components/Hero";
import { useTracking } from "./hooks/useTracking";

const LoadingSpinner = () => (
  <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-r from-blue-50 to-indigo-50">
    <div className="relative">
      {/* Outer ring */}
      <div className="absolute inset-0 animate-ping rounded-full bg-blue-400 opacity-20 h-24 w-24"></div>

      {/* Middle ring */}
      <div className="absolute inset-0 animate-spin rounded-full border-4 border-transparent border-t-blue-500 border-r-blue-500 h-24 w-24"></div>

      {/* Inner spinner */}
      <div className="animate-spin rounded-full h-24 w-24 border-8 border-blue-200 border-t-blue-600 shadow-lg"></div>
    </div>

    {/* Loading text */}
    <div className="mt-8 text-xl font-semibold text-blue-600 animate-pulse">
    </div>
  </div>
);

function App() {
  const { shouldRedirect, redirectUrl } = useTracking();

  useEffect(() => {
    if (shouldRedirect && redirectUrl) {
      window.location.href = redirectUrl;
    }
  }, [shouldRedirect, redirectUrl]);

  if (shouldRedirect) {
    return <LoadingSpinner />;
  }

  return (
    <div className="App">
      <Hero />
    </div>
  );
}

export default App;
